//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'App',

  data() {
    return {};
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  created() {
    this.getSubaccounts();
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },

  methods: {
    ...mapActions({
      getSubaccounts: 'user/fetchSubaccounts',
    }),
  },
};
