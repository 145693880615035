<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu[6]') }}
    </h1>
    <div class="flex -mx-3">
      <div class="w-full px-3 mb-5">
        <div class="mb-10 items-center">
          <span class="text-sm text-grey block mr-10">
            {{ $t('webhooks.index[0]') }}
          </span>

          <div
            v-if="webhookList.some(wh => wh && wh.type && typeList[wh.type].deprecated)"
            class="border border-yellow-dark bg-yellow-lightest text-yellow-darkest rounded-sm inline-block p-2 mt-5 text-xs leading-loose"
          >
            <p>
              {{ $t('webhooks.index[2]') }} ⚠️ <span class="font-bold text-red-dark">{{ $t('webhooks.index[3]') }}.</span>
            </p>
            <p>
              {{ $t('webhooks.index[4]') }} <a
                href="https://developer.8x8.com/connect/reference#sms-callbacks-reference"
                target="_blank"
                class="no-underline text-blue-dark"
              >{{ $t('webhooks.index[5]') }}</a> {{ $t('webhooks.index[6]') }} <a
                href="https://developer.8x8.com/connect/reference#chatapps-callbacks-reference"
                target="_blank"
                class="no-underline text-blue-dark"
              >{{ $t('webhooks.index[7]') }}</a> {{ $t('webhooks.index[8]') }}.
              {{ $t('webhooks.index[9]') }} <a
                href="https://developer.8x8.com/connect/docs/webhook-migration-guide"
                target="_blank"
                class="no-underline text-blue-dark"
              >{{ $t('webhooks.index[10]') }}</a>
            </p>
          </div>
        </div>
        <Table
          :loading="loading"
          :data="getPaginatedData()"
          :map-data="webhookMap"
          :type-list="typeList"
          @update-status="updateStatus"
          @delete="deleteWebhookItem"
          @edit="showWebhookModal"
          @add-webhook="() => formShow = true"
        />
        <Pagination
          v-if="webhookList.length"
          :index="index"
          :limit="limit"
          :data="webhookList"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
      <div class="main__aside px-3">
        <div class="pt-20 -mt-20 sticky pin-t">
          <el-button
            :loading="loading"
            class="mb-5 w-full"
            type="primary"
            @click.prevent="() => formShow = true"
          >
            {{ $t('webhooks.index[1]') }}
          </el-button>
          <Filters
            :loading="loading"
            :filters="filters"
            :subaccount-list="subaccountList"
            :type-list="undeprecatedTypes()"
            @update-filter="updateFilter"
          />
        </div>
      </div>
    </div>
    <FormModal
      :default-form-value="selectedWebhook"
      :wh-visible="formShow"
      :subaccount-list="subaccountList"
      :type-list="undeprecatedTypes()"
      @close-modal="closeModal"
      @submit="submitForm"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Filters from './partials/Filters.vue';
import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import FormModal from './partials/WebhookModal.vue';
import typeList from '../../json/webhookTypes.json';

export default {
  name: 'Home',

  components: {
    Filters,
    Table,
    Pagination,
    FormModal,
  },

  data() {
    return {
      formShow: false,

      typeList,

      // Pagination
      limit: 15,
    };
  },

  computed: {
    ...mapGetters({
      webhookList: 'webhooks/getList',
      webhookMap: 'webhooks/getDictionary',
      filters: 'webhooks/getFilters',
      loading: 'webhooks/getLoading',
      index: 'webhooks/getIndex',
      selectedWebhook: 'webhooks/getSelected',
      subaccountList: 'user/getSubaccounts',
    }),
  },

  created() {
    this.getWebhooks();
  },

  mounted() {
    window.addEventListener('localeChanged', () => {
      this.$i18n.locale = this.$getSelectedLocale();
    });
  },

  methods: {
    ...mapMutations({
      setFilters: 'webhooks/SET_FILTERS',
      setIndex: 'webhooks/SET_PAGINATION_INDEX',
      setSelectedWebhook: 'webhooks/SET_SELECTED',
    }),

    ...mapActions({
      getWebhooks: 'webhooks/fetchWebhooks',
      createWebhook: 'webhooks/createWebhook',
      updateWebhook: 'webhooks/updateWebhook',
      deleteWebhook: 'webhooks/deleteWebhook',
    }),

    undeprecatedTypes() {
      const types = this.typeList;
      const list = Object.keys(types).reduce((acc, curr) => {
        if (types[curr].deprecated) {
          return acc;
        }

        return {
          ...acc,
          [curr]: { ...types[curr] },
        };
      }, {});
      return list;
    },

    submitForm(form = {}) {
      const {
        formType,
        ...payload
      } = form;

      if (formType === 'CREATE') { // Create webhook
        this.createWebhook(payload).then(() => {
          this.$message.success(this.$t('success.created', { value: 'webhook' }));
          // Set selected webhook to empty
          this.setSelectedWebhook('');
          this.formShow = false;
        }).catch((err) => {
          const { data } = err;
          if (this.webhookList.some(wh => this.typeList[wh.type].deprecated) && data && data.error.match(/validation_failed/)) {
            this.$showError(this, {
              error: this.$t('apiErrors.webhook_version_conflict_old_format_exists'),
            });
            return;
          }
          this.$showError(this, err);
        });
      } else { // Edit webhook
        this.$confirm(this.$t('confirmations.modify', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
          confirmButtonText: this.$t('actions.yes'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        }).then(() => {
          this.updateWebhook(payload).then(() => {
            this.$message.success(this.$t('success.updated', { value: 'webhook' }));
            // Set selected webhook to empty
            this.setSelectedWebhook('');
            this.formShow = false;
          }).catch((err) => {
            this.$showError(this, err);
          });
        }).catch(() => 0);
      }
    },

    updateFilter(payload = {}) {
      this.setFilters(payload);

      // Update webhooks data list
      this.getWebhooks().then(() => {
        // this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    closeModal() {
      // Set selected webhook to empty
      this.setSelectedWebhook('');
      this.formShow = false;
    },

    showWebhookModal(payload = '') {
      this.setSelectedWebhook(payload);
      this.formShow = true;
    },

    deleteWebhookItem(payload = {}) {
      this.$confirm(this.$t('confirmations.remove', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
        confirmButtonText: this.$t('actions.remove'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.deleteWebhook(payload).then(() => {
          this.$message.success(this.$t('success.deleted', { value: 'webhook' }));
        }).catch((err) => {
          this.$showError(this, err);
        });
      }).catch(() => 0);
    },

    updateStatus(payload = {}) {
      this.updateWebhook({
        ...payload,
        enabled: !payload.enabled,
      }).then(() => {
        this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    getPaginatedData() {
      const p = this.index * this.limit;
      return this.webhookList.slice(p, p + 1 * this.limit);
    },

    // prev pagination
    prevPage() {
      this.setIndex(this.index - 1);
    },

    // next pagination
    nextPage() {
      this.setIndex(this.index + 1);
    },
  },
};
</script>
