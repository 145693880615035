import Vue from 'vue';
import Vuex from 'vuex';
// import { Message } from 'element-ui';

import user from './user';
import webhooks from './webhooks';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  getters: {},

  mutations: {
    API_ERROR(state, payload) {
      if (window.Bugsnag && typeof payload === 'object') {
        window.Bugsnag.notify(payload);
      }

      state.errors.push(payload); // eslint-disable-line no-param-reassign

      // Message({
      //   message: error.message || error.error,
      //   showClose: true,
      //   duration: 0,
      //   type: 'error',
      // });

      // eslint-disable-next-line no-unused-vars
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
    },
  },

  actions: {},

  modules: {
    user,
    webhooks,
  },

  strict: debug,
});

export default store;
