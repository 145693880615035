/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    subaccounts: [],
  },

  getters: {
    getSubaccounts: st => st.subaccounts,
  },

  mutations: {
    SET_SUBACCOUNTS(state, payload) {
      state.subaccounts = payload;
    },
  },

  actions,
};
