//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    },

    mapData: {
      type: Object,
      default: () => {},
    },

    typeList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {
    handleCommand(c) {
      switch (c.command) {
      case 'EDIT':
        this.$emit('edit', c.content);
        break;
      case 'ENABLE':
        this.$emit('update-status', c.content);
        break;
      case 'DELETE':
        this.$emit('delete', c.content);
        break;
      default:
        break;
      }
    },
  },
};
