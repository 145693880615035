// import { Message } from 'element-ui';
import http from '../../../../utils/http';

export default {
  // Fetch webhooks data from api
  fetchWebhooks: async ({ commit, state }, reloadList = true) => {
    if (reloadList) {
      commit('SET_LOADING', true);
    }

    const {
      subaccount,
      type,
    } = state.filters;

    try {
      const { data } = await http.v2.get(`webhooks?subAccountId=${subaccount}&type=${type}`);

      // for temporary only, this will be removed if backend supported these voice webhooks
      const voiceWebhooks = ['VCA', 'VSS', 'VCS', 'VRU'];

      const dataWithoutVoiceWebhook = data.filter((v) => { // eslint-disable-line
        if (v && v.type && !voiceWebhooks.includes(v.type)) {
          return v;
        }
      });

      // Create a dictionary for webhooks to modify state and access data easier
      const newDict = dataWithoutVoiceWebhook.reduce((dict, item, index) => ({
        ...dict,
        [index]: {
          ...item,
          loading: false, // add dedicated loading per item
          keyValue: index,
        },
      }), {});
      commit('SET_WEBHOOKS_DICTIONARY', newDict);

      // Set pagination index to 0
      if (reloadList) {
        commit('SET_PAGINATION_INDEX', 0);
      }

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Create webhooks
  createWebhook: async ({ commit, dispatch }, payload = {}) => {
    commit('SET_LOADING', true);

    try {
      await http.v2.post('webhooks', payload);
      // Message.success('Created new webhook');
      // Fetch new webhook data
      dispatch('fetchWebhooks');
      return 0;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Edit webhooks
  updateWebhook: async ({ commit, dispatch }, payload = {}) => {
    const {
      subAccountId,
      type,
      url,
      httpAuthorization,
      enabled,
      keyValue: key,
    } = payload;

    // Set loading to specific webhook item
    commit('UPDATE_DICTIONARY_VALUE', {
      key,
      payload: { loading: true },
    });

    const p = {
      subAccountId,
      type,
      url,
      httpAuthorization,
      enabled,
    };

    try {
      await http.v2.post('webhooks', p);
      // Message.success('Updated webhook');
      // Fetch new webhook data
      dispatch('fetchWebhooks', false);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      // Set loading to specific webhook item
      commit('UPDATE_DICTIONARY_VALUE', {
        key,
        payload: { loading: false },
      });
    }
  },

  // Delete webhooks
  deleteWebhook: async ({ commit, dispatch }, payload = {}) => {
    const {
      subAccountId,
      type,
    } = payload;

    const p = {
      subAccountId,
      type,
    };

    commit('SET_LOADING', true);

    try {
      await http.v2.delete(`webhooks/${subAccountId}`, p);
      // Message.success('Webhook removed');
      // Fetch new webhook data
      dispatch('fetchWebhooks');

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
