/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    selected: '',
    list: [],
    dictionary: {},
    filters: {
      subaccount: '',
      type: '',
    },
    loading: false,
    paginationIndex: 0,
  },

  getters: {
    getList: st => st.list,
    getDictionary: st => st.dictionary,
    getFilters: st => st.filters,
    getLoading: st => st.loading,
    getIndex: st => st.paginationIndex,
    getSelected: st => st.selected,
  },

  mutations: {
    SET_SELECTED(state, payload) {
      state.selected = payload;
    },

    SET_WEBHOOKS_DICTIONARY(state, dict) {
      state.dictionary = dict;

      // Update webhook list
      const listData = Object.keys(dict).map(key => ({ ...dict[key] }));
      state.list = listData;
    },

    UPDATE_DICTIONARY_VALUE(state, obj = {}) {
      if (!Object.keys(obj).length) return 0;

      const { key, payload } = obj;
      const newDict = {
        ...state.dictionary[key],
        ...payload,
      };

      state.dictionary = {
        ...state.dictionary,
        [key]: newDict,
      };

      // Update webhook list
      const listData = Object.keys(state.dictionary).map(k => ({ ...state.dictionary[k] }));
      state.list = listData;

      return 0;
    },

    SET_FILTERS(state, payload) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },

    SET_LOADING(state, bool) {
      state.loading = bool;
    },

    SET_PAGINATION_INDEX(state, index = 0) {
      state.paginationIndex = index;
    },
  },

  actions,
};
