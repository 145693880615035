<template>
  <el-dialog
    :title="modalTitle()"
    :visible.sync="whVisible"
    width="40%"
    top="20px"
    destroy-on-close
    custom-class="px-10"
    @close="closeModal"
    @open="resetForm"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      autocomplete="off"
    >
      <!-- Subaccount input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('fields.subaccount') }}
        </p>
        <div v-if="formType === 'CREATE'">
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('webhooks.modal[0]') }}
          </p>
          <el-form-item prop="subAccountId">
            <el-select
              v-model="form.subAccountId"
              :placeholder="$t('webhooks.modal[1]')"
              size="small"
              :style="{width: '60%'}"
              autocomplete="new-password"
              filterable
            >
              <el-option
                v-for="subaccount in [{SubAccountId: '', Label: $t('webhooks.filters[1]') }, ...subaccountList]"
                :key="subaccount.SubAccountId"
                :label="subaccount.Label || subaccount.SubAccountId"
                :value="subaccount.SubAccountId"
              />
            </el-select>
          </el-form-item>
        </div>
        <p
          v-else
          class="text-base mb-5 text-grey-dark flex items-center"
        >
          {{ form.subAccountId }}
          <small
            v-if="form.version"
            class="inline-block font-bold font-bold text-grey rounded-sm border border-grey ml-2 wh-version-tag"
          >
            {{ $t('webhooks.modal[2]') }} {{ form.version }}
          </small>
        </p>
      </div>

      <!-- Webhook type input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('webhooks.modal[3]') }}
        </p>
        <div v-if="formType === 'CREATE'">
          <p class="text-grey-dark mb-3 text-xs">
            {{ $t('webhooks.modal[4]') }}
          </p>
          <el-form-item prop="type">
            <el-radio-group
              v-model="form.type"
            >
              <el-radio
                v-for="type in Object.keys(typeOptions)"
                v-if="!typeOptions[type].hidden"
                :key="type"
                :label="type"
                :class="type.class"
                class="mb-2 flex items-center"
              >
                {{ typeOptions[type].name }}
                <span class="text-xs text-grey ml-1">
                  ({{ $t(`webhooks.desc.${typeOptions[type].description || '-'}`) }})
                </span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <p
          v-else
          class="mb-5 text-sm inline-block font-bold font-bold text-black rounded-sm wh-modal-tag"
        >
          {{ form.type }}
          <span
            v-if="!Object.keys(typeOptions).includes(form.type)"
            class="text-sm text-blue-dark capitalize font-normal ml-2"
          >
            ⚠️{{ $t('webhooks.index[3]').toUpperCase() }}
          </span>
        </p>
      </div>

      <!-- URL input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('webhooks.modal[5]') }}
        </p>
        <p class="text-grey-dark mb-2 text-xs">
          {{ $t('webhooks.modal[6]') }}
        </p>
        <el-form-item prop="url">
          <el-input
            v-model="form.url"
            placeholder="https://your-webhook-url.com"
            size="small"
            class="w-full"
            type="url"
          />
        </el-form-item>
      </div>

      <!-- Auth token input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium flex items-center">
          {{ $t('webhooks.modal[7]') }}
          <el-tooltip
            :enterable="false"
            placement="top"
            effect="light"
          >
            <div slot="content">
              {{ $t('webhooks.modal[8]') }}
              <br>
              {{ $t('webhooks.modal[9]') }}
              <br>
              <code class="text-blue mt-1 text-xs block">Basic dXNcjpwYXNzD29yZA==...</code>
              <br>
              <br>
              {{ $t('webhooks.modal[10]') }}
              <br>
              <code class="text-blue mt-1 text-xs block">Bearer 9QQhisEB8VY...</code>
            </div>
            <i class="el-icon-info ml-1 text-xs text-grey-darker" />
          </el-tooltip>
        </p>
        <p class="text-grey-dark mb-2 text-xs">
          {{ $t('webhooks.modal[11]') }}
        </p>
        <el-form-item prop="httpAuthorization">
          <el-input
            v-model="form.httpAuthorization"
            :placeholder="$t('webhooks.modal[12]')"
            size="small"
            type="textarea"
            :rows="3"
            class="w-full font-mono"
          />
        </el-form-item>
      </div>

      <!-- Enable input -->
      <!-- <div class="block">
        <el-form-item prop="enabled">
          <el-switch
            v-model="form.enabled"
            :active-text="form.enabled ? 'Enabled webhook' : 'Disabled webhook'"
          />
        </el-form-item>
      </div> -->
    </el-form>

    <!-- Buttons -->
    <div class="mt-5 flex justify-end">
      <div>
        <el-button
          type="default"
          size="small"
          @click="closeModal"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          :loading="loading"
          size="small"
          type="primary"
          @click="submit"
        >
          {{ formType === 'CREATE' ? $t('webhooks.modal[13]') : $t('actions.save_changes') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'Webhooksmodal',

  props: {
    defaultFormValue: {
      default: () => {},
      type: Object,
    },

    whVisible: {
      type: Boolean,
      default: false,
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    typeList: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const typeOptions = this.typeList;

    const self = this;

    return {
      formType: 'CREATE', // or EDIT

      emptyForm: {
        subAccountId: '',
        type: '',
        url: '',
        httpAuthorization: '',
        enabled: false,
      },

      form: {},

      typeOptions,

      formRules: {
        subAccountId: [
          { required: true, message: self.$t('validations.select', { value: self.$t('fields.subaccount') }), trigger: 'change' },
        ],

        type: [
          { required: true, message: self.$t('validations.select', { value: self.$t('webhooks.modal[4]') }), trigger: 'change' },
        ],

        url: [
          { required: true, message: self.$t('validations.required', { value: self.$t('webhooks.modal[5]') }), trigger: 'change' },
          { type: 'url', message: self.$t('validations.valid', { value: self.$t('webhooks.modal[5]') }), trigger: 'change' },
        ],

        httpAuthorization: [],

        enabled: [
          { required: false },
        ],
      },
    };
  },

  methods: {
    modalTitle() {
      return this.formType === 'CREATE' ? this.$t('webhooks.modal[13]') : this.$t('webhooks.modal[14]');
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.$emit('close-modal');
    },

    resetForm() {
      this.formType = Object.keys(this.defaultFormValue || {}).length ? 'EDIT' : 'CREATE';

      // Set form default to empty
      if (this.formType === 'CREATE') {
        this.form = { ...this.form, ...this.emptyForm };
      } else { // Set form to defaultFormValue
        this.form = { ...this.defaultFormValue };
      }

      // Remove validation messages after setting form datas
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submit', {
              formType: this.formType,
              ...this.form,
            });
            return true;
          }
          this.$message.error(this.$t('validations.required_fields'));
          return false;
        });
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.wh-modal-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}

.wh-version-tag {
  padding: 3px 4px 2px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
  font-size: 0.6em;
}
</style>
