<template>
  <div>
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      fit
    >
      <template slot="empty">
        <div class="text-center p-10 text-xs text-black leading-loose">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('webhooks.tables[0]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('webhooks.tables[1]') }}
          </span>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="$emit('add-webhook')"
          >
            {{ $t('webhooks.modal[13]') }}
          </el-button>
        </div>
      </template>
      <!-- Subaccount column -->
      <el-table-column
        v-slot="scope"
        :label="$t('fields.subaccount')"
        show-overflow-tooltip
        width="200px"
        fixed
      >
        <div
          v-if="(String(scope.row.subAccountId || '')).split(',').length <= 1"
          class="text-xs text-black"
        >
          {{ scope.row.subAccountId || '' }}
        </div>
        <div
          v-else
          class="text-xs truncate text-black"
        >
          {{ $t('webhooks.filters[1]') }}
        </div>
      </el-table-column>

      <!-- Deprecated column -->
      <el-table-column
        v-slot="scope"
        width="50px"
      >
        <div
          v-if="!scope.row.type && Object.keys(typeList[scope.row.type]).includes('deprecated') &&
            typeList[scope.row.type].deprecated"
          class="text-xs truncate text-red-dark"
        >
          <el-tooltip
            content="Deprecated"
            placement="top"
          >
            <span>⚠️</span>
          </el-tooltip>
        </div>
      </el-table-column>

      <!-- Type column -->
      <el-table-column
        v-slot="scope"
        :label="$t('column_labels.type')"
        width="100px"
        show-overflow-tooltip
      >
        <div
          class="text-xs inline-block font-bold font-bold text-black rounded-sm wh-tag"
        >
          {{ typeList[scope.row.type] ? typeList[scope.row.type].name : '-' }}
        </div>
      </el-table-column>

      <!-- URL column -->
      <el-table-column
        v-slot="scope"
        :label="$t('column_labels.url')"
        width="150px"
        show-overflow-tooltip
      >
        <span
          class="text-xs truncate text-blue no-underline"
          target="_blank"
        >
          {{ (scope.row.url || '').replace(/^(?:https?:\/\/)?(?:www\.)?/i, '') }}
        </span>
      </el-table-column>

      <!-- Auth token column -->
      <el-table-column
        v-slot="scope"
        :label="$t('webhooks.modal[7]')"
        width="150px"
        align="right"
        show-overflow-tooltip
      >
        <code
          v-if="scope.row.httpAuthorization"
          class="text-xs text-black font-mono p-1 px-2 bg-grey-lighter rounded-sm leading-none"
        >
          {{ scope.row.httpAuthorization || '' }}
        </code>
        <code
          v-else
          class="text-xs text-black font-mono p-1 px-2 bg-grey-lighter rounded-sm leading-none"
        >
          {{ $t('webhooks.tables[2]') }}
        </code>
      </el-table-column>

      <!-- Content type column -->
      <el-table-column
        v-slot="scope"
        :label="$t('column_labels.content_type')"
        show-overflow-tooltip
        width="150px"
      >
        <div class="text-xs truncate text-black">
          {{ scope.row.httpContentType || '' }}
        </div>
      </el-table-column>

      <!-- Status column -->
      <el-table-column
        :label="$t('column_labels.status')"
        width="60px"
        align="center"
        class="flex items-center"
      >
        <template slot-scope="scope">
          <div class="flex items-center justify-center">
            <el-switch
              v-show="!mapData[scope.row.keyValue].loading"
              :value="scope.row.enabled"
              @change="$emit('update-status', scope.row)"
            />
            <i
              v-show="mapData[scope.row.keyValue].loading"
              class="el-icon-loading text-lg text-red"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        width="25px"
        align="right"
      >
        <template slot-scope="scope">
          <!-- Dropdown column -->
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-more" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :disabled="mapData[scope.row.keyValue].loading"
                :command="{ command: 'EDIT', content: scope.row }"
              >
                {{ $t('actions.edit') }}
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="mapData[scope.row.keyValue].loading"
                :command="{ command: 'ENABLE', content: scope.row }"
              >
                {{ scope.row.enabled ? $t('actions.disable') : $t('actions.enable') }}
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="mapData[scope.row.keyValue].loading"
                :command="{ command: 'DELETE', content: scope.row }"
              >
                <span class="text-red">{{ $t('actions.delete') }} </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    },

    mapData: {
      type: Object,
      default: () => {},
    },

    typeList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {
    handleCommand(c) {
      switch (c.command) {
      case 'EDIT':
        this.$emit('edit', c.content);
        break;
      case 'ENABLE':
        this.$emit('update-status', c.content);
        break;
      case 'DELETE':
        this.$emit('delete', c.content);
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style lang="scss">
.wh-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>
