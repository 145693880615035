import http from '../../../../utils/http';

export default {
  // Fetch subaccount list
  fetchSubaccounts: async ({ commit }) => {
    try {
      const { data } = await http.v2.get('webhooks/subaccounts');
      commit('SET_SUBACCOUNTS', data);
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      throw new Error(err.message);
    }
  },
};
