//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Pagination',

  props: {
    index: {
      default: 0,
      type: Number,
    },
    limit: {
      default: 0,
      type: Number,
    },
    data: {
      default: () => [],
      type: Array,
    },
  },

  methods: {
    prev() {
      if (!this.loading) {
        this.$emit('prev-page');
      }
    },

    next() {
      if (!this.loading) {
        this.$emit('next-page');
      }
    },
  },
};
