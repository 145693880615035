<template>
  <div class="pagination flex justify-end items-center text-xs text-grey-dark leading-none">
    <div class="flex">
      <a
        v-if="index >= 1"
        class="pagination__arrow p-3 inline-block leading-none cursor-pointer"
        :class="{ 'pagination__arrow--disable': loading }"
        @click.prevent="prev"
      >
        <i class="el-icon-arrow-left" />
      </a>
      <div class="p-3 bg-white">
        {{ index + 1 }}
      </div>
      <a
        v-if="(index+1) * limit < data.length"
        class="pagination__arrow p-3 inline-block leading-none cursor-pointer"
        :class="{ 'pagination__arrow--disable': loading }"
        @click.prevent="next"
      >
        <i class="el-icon-arrow-right" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    index: {
      default: 0,
      type: Number,
    },
    limit: {
      default: 0,
      type: Number,
    },
    data: {
      default: () => [],
      type: Array,
    },
  },

  methods: {
    prev() {
      if (!this.loading) {
        this.$emit('prev-page');
      }
    },

    next() {
      if (!this.loading) {
        this.$emit('next-page');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  background-color: #E4EDFF;

  &__arrow {
    background-color: #F2F5FF;
    transition: background-color 0.05s ease-in;

    &:hover {
      background-color: darken(#F2F5FF, 1);
    }

    &--disable {
      cursor: progress !important;
      opacity: 0.5;
    }
  }
}
</style>
