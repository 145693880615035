//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Webhooksmodal',

  props: {
    defaultFormValue: {
      default: () => {},
      type: Object,
    },

    whVisible: {
      type: Boolean,
      default: false,
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    typeList: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const typeOptions = this.typeList;

    const self = this;

    return {
      formType: 'CREATE', // or EDIT

      emptyForm: {
        subAccountId: '',
        type: '',
        url: '',
        httpAuthorization: '',
        enabled: false,
      },

      form: {},

      typeOptions,

      formRules: {
        subAccountId: [
          { required: true, message: self.$t('validations.select', { value: self.$t('fields.subaccount') }), trigger: 'change' },
        ],

        type: [
          { required: true, message: self.$t('validations.select', { value: self.$t('webhooks.modal[4]') }), trigger: 'change' },
        ],

        url: [
          { required: true, message: self.$t('validations.required', { value: self.$t('webhooks.modal[5]') }), trigger: 'change' },
          { type: 'url', message: self.$t('validations.valid', { value: self.$t('webhooks.modal[5]') }), trigger: 'change' },
        ],

        httpAuthorization: [],

        enabled: [
          { required: false },
        ],
      },
    };
  },

  methods: {
    modalTitle() {
      return this.formType === 'CREATE' ? this.$t('webhooks.modal[13]') : this.$t('webhooks.modal[14]');
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.$emit('close-modal');
    },

    resetForm() {
      this.formType = Object.keys(this.defaultFormValue || {}).length ? 'EDIT' : 'CREATE';

      // Set form default to empty
      if (this.formType === 'CREATE') {
        this.form = { ...this.form, ...this.emptyForm };
      } else { // Set form to defaultFormValue
        this.form = { ...this.defaultFormValue };
      }

      // Remove validation messages after setting form datas
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('submit', {
              formType: this.formType,
              ...this.form,
            });
            return true;
          }
          this.$message.error(this.$t('validations.required_fields'));
          return false;
        });
      }
      return false;
    },
  },
};
