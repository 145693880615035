import Layout from './views/Layout.vue';
import Home from './views/home/Home.vue';

export default [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
    ],
  },
];
