<template>
  <div class="l-container relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <!-- date range Select -->
        <span class="text-xs block mb-2">{{ $t('fields.subaccount') }}</span>
        <el-select
          :value="filters.subaccount"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ subaccount: v })"
        >
          <el-option
            v-for="subaccount in [{ SubAccountId: '' }, ...subaccountList]"
            :key="subaccount.SubAccountId"
            :label="subaccount.SubAccountId || $t('webhooks.filters[1]')"
            :value="subaccount.SubAccountId"
          />
        </el-select>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <span class="text-xs block mb-2"> {{ $t('webhooks.filters[0]') }}</span>
      <div class="flex flex-col">
        <!-- Type Select -->
        <div class="relative">
          <el-radio-group
            v-model="typeValue"
            class="flex w-full flex-wrap"
            @change="v => updateFilter({ type: v })"
          >
            <el-radio
              v-for="type in Object.keys(typeOptions)"
              v-if="!typeOptions[type].hidden"
              :key="type"
              :label="type"
              :class="type.class"
              class="my-2 mx-0 flex w-1/2 items-center"
            >
              <div class="flex items-center">
                <span>{{ typeOptions[type].name == 'ALL' ? $t('column_labels.all') : typeOptions[type].name }}</span>
                <el-tooltip
                  :content="$t(`webhooks.desc.${typeOptions[type].description || '-'}`)"
                  :enterable="false"
                  placement="top"
                  effect="dark"
                >
                  <i class="el-icon-info ml-1 text-xs text-grey-darker" />
                </el-tooltip>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      type: Object,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    typeList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const typeOptions = {
      '': {
        name: 'ALL',
        description: 'Event of all types',
        class: 'bg-purple',
      },
      ...this.typeList,
    };

    return {
      typeOptions,
      typeValue: '',
    };
  },

  created() {
    this.typeValue = this.filters.type;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
</script>
