import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import ElementUI from 'element-ui';
// import enLocale from 'element-ui/lib/locale/lang/en';
import VueI18n from 'vue-i18n';
import { sync } from 'vuex-router-sync';

import VueJstz from './plugins/vue-jstz';
import VueZxcvbn from './plugins/vue-zxcvbn';
import App from './App.vue';
import routes from './router';
import store from './store';
import Constants from './constants';

import ErrorUIHandler from '../common/handle-error-msg';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);

Vue.use(Constants);

const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE'), // set locale
  fallbackLocale: 'en',
});

Vue.use(VueJstz);
Vue.use(VueZxcvbn);
Vue.use(VueRouter);

const router = new VueRouter({
  base: '/webhooks',
  mode: 'history',
  routes,
});

sync(store, router);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#webhooks',
    i18n,
    store,
    router,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const {
        localeMessages, langUtil, telemetry,
      } = this;
      Vue.prototype.$telemetry = telemetry;

      Vue.use(ErrorUIHandler);

      return h(App, { props: { localeMessages, langUtil } });
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
