//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      type: Object,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },

    typeList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const typeOptions = {
      '': {
        name: 'ALL',
        description: 'Event of all types',
        class: 'bg-purple',
      },
      ...this.typeList,
    };

    return {
      typeOptions,
      typeValue: '',
    };
  },

  created() {
    this.typeValue = this.filters.type;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
