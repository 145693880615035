//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapGetters } from 'vuex';
import Filters from './partials/Filters.vue';
import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import FormModal from './partials/WebhookModal.vue';
import typeList from '../../json/webhookTypes.json';

export default {
  name: 'Home',

  components: {
    Filters,
    Table,
    Pagination,
    FormModal,
  },

  data() {
    return {
      formShow: false,

      typeList,

      // Pagination
      limit: 15,
    };
  },

  computed: {
    ...mapGetters({
      webhookList: 'webhooks/getList',
      webhookMap: 'webhooks/getDictionary',
      filters: 'webhooks/getFilters',
      loading: 'webhooks/getLoading',
      index: 'webhooks/getIndex',
      selectedWebhook: 'webhooks/getSelected',
      subaccountList: 'user/getSubaccounts',
    }),
  },

  created() {
    this.getWebhooks();
  },

  mounted() {
    window.addEventListener('localeChanged', () => {
      this.$i18n.locale = this.$getSelectedLocale();
    });
  },

  methods: {
    ...mapMutations({
      setFilters: 'webhooks/SET_FILTERS',
      setIndex: 'webhooks/SET_PAGINATION_INDEX',
      setSelectedWebhook: 'webhooks/SET_SELECTED',
    }),

    ...mapActions({
      getWebhooks: 'webhooks/fetchWebhooks',
      createWebhook: 'webhooks/createWebhook',
      updateWebhook: 'webhooks/updateWebhook',
      deleteWebhook: 'webhooks/deleteWebhook',
    }),

    undeprecatedTypes() {
      const types = this.typeList;
      const list = Object.keys(types).reduce((acc, curr) => {
        if (types[curr].deprecated) {
          return acc;
        }

        return {
          ...acc,
          [curr]: { ...types[curr] },
        };
      }, {});
      return list;
    },

    submitForm(form = {}) {
      const {
        formType,
        ...payload
      } = form;

      if (formType === 'CREATE') { // Create webhook
        this.createWebhook(payload).then(() => {
          this.$message.success(this.$t('success.created', { value: 'webhook' }));
          // Set selected webhook to empty
          this.setSelectedWebhook('');
          this.formShow = false;
        }).catch((err) => {
          const { data } = err;
          if (this.webhookList.some(wh => this.typeList[wh.type].deprecated) && data && data.error.match(/validation_failed/)) {
            this.$showError(this, {
              error: this.$t('apiErrors.webhook_version_conflict_old_format_exists'),
            });
            return;
          }
          this.$showError(this, err);
        });
      } else { // Edit webhook
        this.$confirm(this.$t('confirmations.modify', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
          confirmButtonText: this.$t('actions.yes'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        }).then(() => {
          this.updateWebhook(payload).then(() => {
            this.$message.success(this.$t('success.updated', { value: 'webhook' }));
            // Set selected webhook to empty
            this.setSelectedWebhook('');
            this.formShow = false;
          }).catch((err) => {
            this.$showError(this, err);
          });
        }).catch(() => 0);
      }
    },

    updateFilter(payload = {}) {
      this.setFilters(payload);

      // Update webhooks data list
      this.getWebhooks().then(() => {
        // this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    closeModal() {
      // Set selected webhook to empty
      this.setSelectedWebhook('');
      this.formShow = false;
    },

    showWebhookModal(payload = '') {
      this.setSelectedWebhook(payload);
      this.formShow = true;
    },

    deleteWebhookItem(payload = {}) {
      this.$confirm(this.$t('confirmations.remove', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
        confirmButtonText: this.$t('actions.remove'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.deleteWebhook(payload).then(() => {
          this.$message.success(this.$t('success.deleted', { value: 'webhook' }));
        }).catch((err) => {
          this.$showError(this, err);
        });
      }).catch(() => 0);
    },

    updateStatus(payload = {}) {
      this.updateWebhook({
        ...payload,
        enabled: !payload.enabled,
      }).then(() => {
        this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    getPaginatedData() {
      const p = this.index * this.limit;
      return this.webhookList.slice(p, p + 1 * this.limit);
    },

    // prev pagination
    prevPage() {
      this.setIndex(this.index - 1);
    },

    // next pagination
    nextPage() {
      this.setIndex(this.index + 1);
    },
  },
};
